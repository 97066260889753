<template>
    <b-modal
      id="modal-view-invoice-VAT"
      v-model="isOpen"
      size="xl"
      centered
      hide-header
      :no-close-on-backdrop="true"
    >
        <b-card-header
            class="banned-background-color"
        >
            <div class="d-flex justify-content-between align-items-center block-header">
            <h4 class="mb-0">
              {{ $t('golf_invoice_view_detail_EInvoice') }}
            </h4>
            <ez-icon
                icon="ezGolf-icon-x"
                class="cursor-pointer"
                size="24"
                @click="hideModal"
            />
            </div>
        </b-card-header>
        
        <b-card-body>
            <iframe id="viewDetailEinvocie" style="height: 100%;width: 100%;">

            </iframe>
        </b-card-body>
            
        <template #modal-footer>
            <b-button   v-if="InvoiceDetail.EinvoiceStatus != 'PUBLISH'"  
                        variant="primary" 
                        @click="issueEinvocie_IIVAT03"
                        :disabled="isLoading"
                    >
                {{ $t("golf_sidebar_checkout_public_order") }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
                
            </b-button>
            <b-button v-if="InvoiceDetail.EinvoiceStatus == 'PUBLISH'" variant="danger" :disabled="isLoading" @click="deteleInvocie_DIVAT03">
                {{ $t("golf_sidebar_checkout_delete_order") }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
                
            </b-button>
            <b-button  variant="secondary" @click="hideModal" :disabled="isLoading">
                {{ $t('golf_common_back') }}
                <b-spinner
                  v-if="isLoading"
                  small
                  label="Loading..."
                />
            </b-button>
        </template>
        
    </b-modal>
</template>
<script>
import { invoice } from '@/api/invoice'
export default {
    data() {
        return {
            isOpen: false,
            urliframe: null,
            InvoiceDetail: {
                EinvoiceStatus: ''
            },
            isLoading: false
        }
    },
    props: [
        'data'
    ],
    watch: {
        isOpen(value) {
            if(!value) {
                this.$emit('event', {type: 'refresh_list_invoice'})
            }
        }
    },
    methods: {
        hideModal() {
            this.isOpen = false
        },
        async open() {
            this.isLoading = true
            await this.detailInvocie_RIVAT02()
            setTimeout(() => {
                document.querySelector('#viewDetailEinvocie').src = this.data.urlInvocie;
                this.isLoading = false
            },500)
        },
        async issueEinvocie_IIVAT03() {
            this.isLoading = true
            var body = {
                InvoiceGolfId: this.data.dataInvoice
            }
            await this.PublicInvoice_UIVAT02().then(async response => { 
                if(response.Status == 200) {
                    const res = await invoice.api_IIVAT03(body)
                    await this.detailInvocie_RIVAT02()
                    if(res.Status == 200) {
                        await this.viewEInvocie_RIVAT03(this.data.dataInvoice, 'DownloadPDF')
                    }
                    this.showResToast(res)

                } else {
                    this.showResToast(response)
                }
                this.isLoading = false
            })
            // await invoice.api_IIVAT03(body).then(async res => {
            //     if(res.Status == 200) {
            //         await this.PublicInvoice_UIVAT02().then(async response => {
            //             this.showResToast(response)
            //             await this.detailInvocie_RIVAT02()
            //             await this.viewEInvocie_RIVAT03(this.data.dataInvoice, 'DownloadPDF')
            //         })
                    
            //     } else {
            //         this.showResToast(res)
            //     }
            //     this.isLoading = false
            // })
        },
        async viewEInvocie_RIVAT03(InvocieGolfId, ViewType) {
            const body = {
                InvoiceGolfId: InvocieGolfId,
                Type: ViewType
            }
            const response =  await invoice.api_RIVAT03(body)
            if(response.Status == 200) {
                const decodedData = Buffer.from(response.Data.InvoicePDF, 'base64')
                const newBlob = new Blob([decodedData], {
                    type: 'application/pdf',
                })
                const url = window.URL.createObjectURL(newBlob)
                document.querySelector('#viewDetailEinvocie').src = url
            }
        },
        async detailInvocie_RIVAT02() {
            const body = {
                Invoice: {
                    Id: this.data.dataInvoice
                }
            }
            const response =  await invoice.api_RIVAT02(body)
            if(response.Status == 200) {
                this.InvoiceDetail = response.Data.Invoice
            }
        },
        async deteleInvocie_DIVAT03 () {
            this.isLoading = true
            const body = {
                InvoiceGolfId: this.data.dataInvoice
            }
            const response =  await invoice.api_DIVAT03(body)
            if(response.Status == 200 ) {
                await invoice.api_UIVAT03({
                    Invoice: {
                    Id: this.data.dataInvoice
                    }
                }).then(async res => {
                    await this.detailInvocie_RIVAT02()
                    await this.viewEInvocie_RIVAT03(this.data.dataInvoice, 'DownloadPDFInvoiceDraft')
                    this.showResToast(res)
                })
            } else {
                await this.detailInvocie_RIVAT02()
                await this.viewEInvocie_RIVAT03(this.data.dataInvoice, 'DownloadPDFInvoiceDraft')
                this.showResToast(response)
            }
            this.isLoading = false
        },
        async PublicInvoice_UIVAT02() {
            const body = {
                Invoice: {
                    Id: this.data.dataInvoice,
                    FormNo: this.InvoiceDetail.FormNo,
                    SerialNo: this.InvoiceDetail.SerialNo,
                    InvoiceNo: null,
                    InvoiceDate: this.InvoiceDetail.InvoiceDate
                }
            }
            const response = await invoice.api_UIVAT02(body)
            return response
        }
    }
}
</script>
<style lang="scss">
    #modal-view-invoice-VAT {
        .modal-dialog {
            height: 95%;
            max-height:95%;
            min-height: 95%;
        }
        .b-overlay-wrap {
            height: 100%;
            
        }
        .modal-content {
            height:100%;
            margin: auto;
        }
       .card-body {
            height: 94%;
       }
    }
</style>