import {
  ROOT,
  API_RPMI01,
  API_RPMI02,
  API_IIVAT01,
  API_RIVAT01A,
  API_RIVAT02,
  API_UIVAT02,
  API_RIVAT01,
  API_DIVAT01,
  API_UIVAT01,
  API_DIVAT02,
  API_IIVAT02,
  API_IIVAT03,
  API_RIVAT03,
  API_DIVAT03,
  API_UIVAT03,
  API_UIVAT04,
  API_IICF01,
  API_RICF01,
  API_DICF01,
  API_UICF01,
} from '@/api/constant'
import { handleResponse } from '@/api/handle-response'
import { requestOptions } from '@/api/request-options'

function api_UIVAT04(data) {
  return fetch(ROOT + API_UIVAT04, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}


function api_UIVAT03(data) {
  return fetch(ROOT + API_UIVAT03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DIVAT03(data) {
  return fetch(ROOT + API_DIVAT03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IIVAT02(data) {
  return fetch(ROOT + API_IIVAT02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IIVAT03(data) {
  return fetch(ROOT + API_IIVAT03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RIVAT03(data) {
  return fetch(ROOT + API_RIVAT03, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RPMI01(data) {
  return fetch(ROOT + API_RPMI01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RPMI02(data) {
  return fetch(ROOT + API_RPMI02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IIVAT01(data) {
  return fetch(ROOT + API_IIVAT01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_RIVAT01A(data) {
  return fetch(ROOT + API_RIVAT01A, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RIVAT02(data) {
  return fetch(ROOT + API_RIVAT02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UIVAT02(data) {
  return fetch(ROOT + API_UIVAT02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RIVAT01(data) {
  return fetch(ROOT + API_RIVAT01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DIVAT01(data) {
  return fetch(ROOT + API_DIVAT01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_UIVAT01(data) {
  return fetch(ROOT + API_UIVAT01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_DIVAT02(data) {
  return fetch(ROOT + API_DIVAT02, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

function api_IICF01(data) {
  return fetch(ROOT + API_IICF01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_RICF01(data) {
  return fetch(ROOT + API_RICF01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_UICF01(data) {
  return fetch(ROOT + API_UICF01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}
function api_DICF01(data) {
  return fetch(ROOT + API_DICF01, requestOptions.postBody(data))
    .then(handleResponse)
    .then(data => data)
}

export const invoice = {
  api_RPMI01,
  api_RPMI02,
  api_IIVAT01,
  api_RIVAT01A,
  api_RIVAT02,
  api_UIVAT02,
  api_RIVAT01,
  api_DIVAT01,
  api_UIVAT01,
  api_DIVAT02,
  api_IIVAT02,
  api_IIVAT03,
  api_RIVAT03,
  api_DIVAT03,
  api_UIVAT03,
  api_UIVAT04,
  api_IICF01,
  api_RICF01,
  api_UICF01,
  api_DICF01
}
